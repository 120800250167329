<template>
  <div class="container">
    <div class="bg-light p-5 rounded-lg mt-4 mb-3 m-2">
      <h1 class="display-4">Καλωσήρθες στον Τρελό Φοιτητή!</h1>
      <p class="lead">
        Ο ΤρελόςΦοιτητής είναι η
        <strong>μεγαλύτερη συλλογή αρχείων πανελλαδικά</strong>
        με περισσότερα απο
        <strong>55,000 ενεργά μέλη</strong>
        , που έχουν πραγματοποιήσει συνολικά πάνω απο
        <strong>ένα εκατομμύριο (1,000,000+) downloads!</strong>
        Εδώ μπορείς να βρείς περισσότερα από
        <strong>
          25,000 θέματα εξετάσεων, από 5,000 πανεπιστημιακά μαθήματα, προερχόμενα από 270 Τμήματα, από 23 ΑΕΙ
        </strong>
        . Εσύ τι περιμένεις για να μπεις στην μεγαλύτερη φοιτητική κοινότητα και να κατεβάσεις καθημερινές σημειώσεις
        μαθημάτων και εκατοντάδες θέματα εξεταστικών μαζί με απαντήσεις και προτεινόμενες λύσεις;
      </p>
      <form accept-charset="UTF-8">
        <div class="input-group">
          <input
            @click="goToSearch"
            @keyup="goToSearch"
            class="form-control"
            type="search"
            placeholder="Γράψε το μάθημα της σχολής σου.." />
        </div>
      </form>
    </div>

    <div class="row row-cols-1 row-cols-md-2 g-4 m-1">
      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Κατέβασε αρχεία άλλων φοιτητών</h5>
            <p class="card-text">
              Διάλεξε μέσα από χιλιάδες διαθέσιμα αρχεία για κατέβασμα και βρες θέματα, σημειώσεις και λύσεις για την
              σχολή σου.
            </p>
            <router-link to="/intro">
              <button class="btn btn-primary stretched-link" type="button">Download</button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Ανέβασε τα δικά σου αρχεία</h5>
            <p class="card-text">
              Έχεις θέματα ή σημειώσεις από τη σχολή σου; Ανέβασε τα και κέρδισε πόντους για να κατέβασεις όποια άλλα
              αρχεία θέλεις.
            </p>
            <router-link to="/upload">
              <button class="btn btn-primary stretched-link" type="button">Upload</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="row align-items-center my-5 m-2">
      <div class="col-lg-7">
        <a href="/">
          <img
            class="img-fluid rounded mb-4 mb-lg-0"
            src="@/assets/images/tf_main.jpg"
            alt="ΤρελοςΦοιτητης logo"
            title="ΤρελοςΦοιτητης Logo" />
        </a>
      </div>
      <div class="col-lg-5">
        <p>
          Βρες πληθώρα θεμάτων εξετάσεων, σημειώσεων και γενικών πληροφοριών για όλες τις σχολές των ΑΕΙ και ΤΕΙ
          ιδρυμάτων της χώρας. Δεν φιλοξενούμε παράνομο υλικό καθώς όλα τα αρχεία προέρχονται είτε απο επιμέρους
          κοινότητες του Web, ή από τους ίδιους τους χρήστες μας. Αν παρόλα αυτά, πιστεύεις οτι κάποιο αρχείο δεν θα
          έπρεπε να είναι εδώ, επικοινώνησε μαζί μας ώστε να διευθετήσουμε το ζήτημα.
        </p>
        <router-link to="/contact">
          <button class="btn btn-primary">Επικοινωνία</button>
        </router-link>
      </div>
    </div>

    <div class="card text-white bg-secondary my-3 py-4 text-center m-2">
      <div class="card-body">
        <p class="text-white m-0">
          Με πάνω από 20,000 θέματα εξεταστικής, ο ΤρελοςΦοιτητης έχει σχεδόν πάντα αυτό που ψάχνεις! &nbsp;
          <router-link to="/signup">
            <button class="btn btn-primary">Δημιουργία Λογαριασμού</button>
          </router-link>
        </p>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-2 g-4 m-1 mb-3">
      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Social Media</h5>
            <p class="card-text">
              <img
                src="@/assets/images/fb.jpg"
                width="30"
                height="30"
                alt="facebook"
                title="Facebook Page"
                class="left" />
              Είσαι στο Facebook; Είμαστε και εμείς! Κάνε like στην σελίδα μας στο Facebook για να μαθαίνεις πρώτος τα
              νέα της σχολής σου!
            </p>
            <div
              class="fb-like"
              data-href="https://www.facebook.com/TrelosFoititis"
              data-layout="button_count"
              data-width="70"
              data-action="like"
              data-show-faces="true"
              data-share="true"></div>
          </div>
          <div class="card-footer">
            <a
              href="https://www.facebook.com/TrelosFoititis/"
              class="btn btn-primary btn-sm stretched-link"
              target="_blank">
              Facebook page
            </a>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Rate My Prof</h5>
            <p class="card-text">
              Βαρέθηκες να σε βαθμολογούν; Σειρά σου να βαθμολογήσεις ΕΣΥ τους καθηγητές σου! Διάβασε κριτικές
              συμφοιτητών σου για το τι να προσέξεις, τι να αποφύγεις και τι να περιμένεις από τους καθηγητές σου, 100%
              ανώνυμα.
            </p>
          </div>
          <div class="card-footer">
            <router-link to="/rate-my-prof">
              <button class="btn btn-primary btn-sm stretched-link" type="button">Γράψε την κριτική σου</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        country_id: 'gr',
        news_utm_source: 'website',
        news_utm_medium: 'homepage',
        news_utm_campaign: 'latest_news',
      };
    },
    methods: {
      goToSearch: function () {
        var self = this;
        self.$router.push('/search');
      },
    },
  };
</script>
